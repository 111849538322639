function IntroDescription({ descriptionText }) {
  const watchDemo = () => {
    window.open("https://youtu.be/teM9wWu0WFg", "blank");
  };

  return (
    <div className="flex flex-col font-poppins-regular justify-between items-center text-center">
      <div className="text-[#032E55] text-[1vw] max-[850px]:text-[4vw] font-bold p-3 m-3">
        {descriptionText}
      </div>

      <div className=" flex  justify-center text-[1vw] max-[850px]:text-[4.25vw] ">
        <a href="mailto:anuj@onfinance.in?subject=NeoGPT%20Free%20Trial&body=Please%20activate%20a%20NeoGPT%20free%20trial%20version%20for%20my%20organization.%20Regards">
          <button className="px-7 max-[1150px]:px-6 max-[1040px]:px-5 max-[920px]:px-3 py-4 mx-2 rm-free-trial-btn no-wrap">
            Get Free Trial
          </button>
        </a>
        <button
          className="px-7 max-[1150px]:px-6 max-[1040px]:px-5 max-[920px]:px-3 py-3 mx-2 rm-watch-demo-btn no-wrap"
          onClick={watchDemo}
        >
          <i className="fa-solid fa-circle-play pr-1 rm-watch-demo-icon"></i>
          Watch Demo
        </button>
      </div>
    </div>
  );
}

export default IntroDescription;
