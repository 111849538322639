const constants = {
  getFreeTrialButtonText: "Get Free Trial",
  watchDemoButtonText: "Watch Demo",
  relationshipManagers: {
    team: "Relationship Managers",
    intro: {
      heading: "Gen AI assistant for Relationship Managers",
      description: "Use NeoGPT to assist your analyst teams with everything from report generation from chain"
    },
    dashboardImg: require("./static/images/relationship-managers-dashboard.png"),
    dashboardImgAlt: "Relationship Managers Dashboard",
    testimonial: {
      message: "“Send about million emails business, It's really important personal having newsletter”",
      profile: "Andrew Jack",
      company: "Motilal Oswal",
      profileIcon: require("./static/images/jack-icon.png")
    },
    features: {
      item1: "Realtime Context",
      iconItem1: require("./static/images/realtime-context.png"),
      item2: "Post-Call Automation",
      iconItem2: require("./static/images/post-call-automation.png"),
      item3: "Relationship Memory",
      iconItem3: require("./static/images/relationship-memory.jpg"),
      item4: "Insightful Follow-ups",
      iconItem4: require("./static/images/insightful-follow-ups.png"),
    },
    animation: {
      heading: "Engage customers with deep personalized understanding",
      description: "NeoGPT automatically detects and enriches real-time context from customer conversations along with insights from customer data."
    },
    copilot: {
      heading: "1 copilot for relationship managers",
      description: "Leverage generative AI within your existing tools and workflows to supercharge your workflows 10x and be more efficient."
    },
    card: {
      heading: "Engage customers with accurate and insightful answers",
      imgPath: require("./static/images/relationship-managers-sales-conversion-rate.png"),
      imgAlt: "Sales Conversion Rate",
      benefit1: {
        heading: "Real-time Context",
        description: "NeoGPT enhances conversations context with relevant public data for better understanding.",
        img: require("./static/images/realtime-context-icon.png"),
        imgAlt: "Real time context"
      },
      benefit2: {
        heading: "Insightful followups",
        description: "NeoGPT detects missing context for answers and generates relevant questions for you to ask",
        img: require("./static/images/insightful-followups-icon.png"),
        imgAlt: "Insightful follow-ups"
      }
    },
    featureCard1: {
      heading: "Impact of long-term memory",
      description: "Discover relevant user data whether that be from a public source, old chat history data, or any internal database on-demand to contextualize responses.",
      img: require("./static/images/long-term-memory.png"),
      imgAlt: "Long term memory"
    },
    featureCard2: {
      heading: "Post-call Automation",
      description: "With NeoGPT you never miss out on an action point from a customer call as it generates a full zapier automation with relevant actions.",
      img: require("./static/images/post-call-automation-feature.png"),
      imgAlt: "Post call automation"
    },
    dataViz: {
      heading: "Integrated with the best CRMs",
      description: "Along with the 10+ conversational platforms and 100+ public data plugins like probe42",
      imgPaths: [
        require("./static/images/salesforce.png"),
        require("./static/images/hubspot.png"),
        require("./static/images/bitrix24.png"),
        require("./static/images/mondaycom.png"),
        require("./static/images/zohocrm.png"),
        require("./static/images/sugarcrm.png"),
        require("./static/images/freshdesk.jpg"),
        require("./static/images/sap.png"),
        require("./static/images/zendesk.png"),
        require("./static/images/probe42.png"),
        require("./static/images/intercom.png"),
        require("./static/images/dynamics365.png")
      ]
    },
    blog: {
      heading: "Resources for relationship management teams",
      btn1: "All",
      btn2: "CX",
      btn3: "Insights"
    }
  },
}

export default constants