import React, { useState } from 'react'
import BlogCards from "./BlogCards";
import clsx from 'clsx'

function Blog({ blog, team }) {
  const [btnSelected, setBtnSelected] = useState(blog.btn1);

  return (
    <div className="my-10 px-[8%]">
      <div className="min-[850px]:grid min-[850px]:grid-cols-2 mb-10 min-[850px]:place-items-end">
        <div className="font-poppins-bold text-black text-left max-[850px]:text-center max-[850px]:mb-10 text-[3vw] max-[850px]:text-[7vw]">
          {blog.heading}
        </div>
        <div className="flex flex-row text-[1.25vw] max-[900px]:text-[1vw] max-[850px]:text-[3vw] max-[520px]:text-[2vw] place-items-end max-[850px]:justify-center">
          <button className={clsx(`font-poppins-regular rounded-lg px-8 py-3 mx-2 h-fit rm-blog-btn`, btnSelected === blog.btn1 ? "blog-btn-selected" : "")} onClick={() => setBtnSelected(blog.btn1)}>
            {blog.btn1}
          </button>
          <button className={clsx(`font-poppins-regular rounded-lg px-8 py-3 mx-2 h-fit rm-blog-btn`, btnSelected === blog.btn2 ? "blog-btn-selected" : "")} onClick={() => setBtnSelected(blog.btn2)}>
            {blog.btn2}
          </button>
          <button className={clsx(`font-poppins-regular rounded-lg px-8 py-3 mx-2 h-fit rm-blog-btn`, btnSelected === blog.btn3 ? "blog-btn-selected" : "")} onClick={() => setBtnSelected(blog.btn3)}>
            {blog.btn3}
          </button>
        </div>
      </div>
      <BlogCards tag={btnSelected} team={team} />
    </div>
  );
}

export default Blog;
