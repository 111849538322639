import Blog from "../components/Blog";
import Body from "../components/Body";
import DataVizTools from "../components/DataVizTools";
import Footer from "../components/Footer";
import Navbar from "../components/NavBar";
import constants from "../constants";

function RelationshipManagers() {
  return (
    <div>
      <Navbar />
      <Body team={constants.relationshipManagers} />
      <DataVizTools
        dataViz={constants.relationshipManagers.dataViz}
      ></DataVizTools>
      <Blog
        blog={constants.relationshipManagers.blog}
        team={constants.relationshipManagers.team}
      ></Blog>
      <Footer />
    </div>
  );
}

export default RelationshipManagers;
