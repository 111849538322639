import React, {useEffect,useState} from "react";
import IMAGES from "../static/images"

function RelationshipManagersAnimation() {
  useEffect(() => {
    const handleIntersection = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (entry.target.id === "chain-connect") {
            setTimeout(() => {
              entry.target.classList.add('animate');
              observer.unobserve(entry.target);
            }, 2000)
          } else if (entry.target.id === "complete-thought-tick") {
            setTimeout(() => {
              entry.target.classList.add('animate');
              observer.unobserve(entry.target);
            }, 3500)
          } else if (entry.target.id === "bar1" || entry.target.id === "bar2" || entry.target.id === "bar3" || entry.target.id === "report-ready-bar" || entry.target.id === "report-ready-text") {
            setTimeout(() => {
              entry.target.classList.add('animate');
              observer.unobserve(entry.target);
            }, 5000)
          } else if (entry.target.id === "report-ready-check") {
            setTimeout(() => {
              entry.target.classList.add('animate');
              observer.unobserve(entry.target);
            }, 6500)
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, { threshold: 0.2 });

    const elements = document.querySelectorAll('.animated-element');
    elements.forEach(element => {
      observer.observe(element);
    });

    return () => {
      elements.forEach(element => {
        observer.unobserve(element);
      });
    };
  }, []);

  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);
  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 1024);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (isDesktop) {
    return (
      <div className="my-10 bg-[#011817] grid grid-cols-2 gap-x-10 p-32 px-40 max-[1400px]:px-32 max-[1200px]:px-24 mx-20 rounded-3xl relative">
        <div className="rm-animation-bg absolute top-[50%] left-[10%] translate-y-[-50%] h-[50%] w-[40%]"></div>
        <div className="absolute right-0">
          <img src={IMAGES.grid} alt="" />
        </div>
        <div className="w-full flex justify-center items-center mt-10">
          <div className="h-full w-[85%] rm-animation-base relative">
            <div className="h-[2px] w-[5%] bg-[#05E1FF] absolute top-[3vw] left-[0.5vw]"></div>
            <div className="w-[25%] font-poppins-regular text-[#05E1FF] text-[1vw] text-left absolute top-[4vw] left-[0.5vw]">
              CUSTOMER DATA
            </div>
            <div className="w-[28%] h-[10vh] absolute top-[10vw] left-[0.5vw] flex flex-row">
              <div id="bar1" className="w-[23%] h-[10vh] bg-[#05E1FF] mx-1 place-self-end animated-element"></div>
              <div id="bar2" className="w-[23%] h-[3vh] bg-[#05E1FF] mx-1 place-self-end opacity-70 animated-element"></div>
              <div id="bar3" className="w-[23%] h-[7vh] bg-[#05E1FF] mx-1 place-self-end opacity-50 animated-element"></div>
            </div>
            <div className="h-full w-[69%] rm-animation-phone absolute right-0 top-[-60px] p-1">
              <div className="text-white font-poppins-regular text-left p-5 text-[1vw]">
                Long term memory
              </div>
              <div className="bg-[#1E9E9E] text-white text-[0.75vw] p-2 mx-5 text-left rounded-sm relative mb-5">
                Question
                <div className="bg-[#FF9800] rounded-full h-[2vh] w-[2vh] absolute right-[-1vh] translate-y-[-100%]"></div>
              </div>
              <div className="bg-[#1E9E9E] text-white text-[0.75vw] p-2 mx-5 text-left rounded-sm relative mt-5">
                Answer
                <div className="bg-[#FF9800] rounded-full h-[2vh] w-[2vh] absolute right-[-1vh] translate-y-[-100%]"></div>
              </div>
              <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="3" height="29" viewBox="0 0 3 29" fill="none">
                <path d="M1.22168 0.850098L1.22168 28.6279" stroke="#BFBEFF" stroke-width="2" stroke-dasharray="3 3"/>
              </svg>
              <div id="chain-connect" className="animated-element"></div>
              <div className="bg-[#A0FFF9] text-black text-[1vw] p-3 mx-5 text-center rounded-lg relative mt-[1.25vw] font-poppins-bold">
                <div className="bg-black font-poppins-bold text-white inline-flex w-fit px-2 py-1 rounded-md self-center mx-auto absolute -top-5 right-[5.5vw]">
                  LTM
                </div>
                <div className="flex flex-col relative pt-2">
                  <div className="grid grid-cols-2 gap-x-5 self-center">
                    <svg className="inline-flex" xmlns="http://www.w3.org/2000/svg" width="22" height="15" viewBox="0 0 22 15" fill="none">
                      <ellipse cx="18.0672" cy="11.1275" rx="3.24074" ry="3.24074" fill="white"/>
                      <circle cx="8.34492" cy="8.81293" r="2.77778" fill="white" fill-opacity="0.7"/>
                      <path d="M0.925827 5.1093C1.22981 5.11039 1.53103 5.0516 1.8123 4.93628C2.09356 4.82096 2.34935 4.65137 2.56507 4.43719C2.78079 4.22302 2.95222 3.96845 3.06956 3.68802C3.18689 3.40759 3.24785 3.1068 3.24894 2.80282C3.25003 2.49883 3.19124 2.19761 3.07592 1.91635C2.9606 1.63509 2.79101 1.37929 2.57683 1.16357C2.36266 0.94785 2.10809 0.776427 1.82766 0.659088C1.54723 0.54175 1.24644 0.480795 0.942457 0.479703L0.934142 2.7945L0.925827 5.1093Z" fill="white" fill-opacity="0.4"/>
                    </svg>
                    <svg className="inline-flex" xmlns="http://www.w3.org/2000/svg" width="21" height="15" viewBox="0 0 21 15" fill="none">
                      <ellipse cx="3.24074" cy="3.24074" rx="3.24074" ry="3.24074" transform="matrix(-1 0 0 1 6.70386 7.88672)" fill="white"/>
                      <circle cx="2.77778" cy="2.77778" r="2.77778" transform="matrix(-1 0 0 1 15.9631 6.03516)" fill="white" fill-opacity="0.7"/>
                      <path d="M20.6042 5.1093C20.3002 5.11039 19.999 5.0516 19.7177 4.93628C19.4365 4.82096 19.1807 4.65137 18.965 4.43719C18.7492 4.22302 18.5778 3.96845 18.4605 3.68802C18.3431 3.40759 18.2822 3.1068 18.2811 2.80282C18.28 2.49883 18.3388 2.19761 18.4541 1.91635C18.5694 1.63509 18.739 1.37929 18.9532 1.16357C19.1674 0.94785 19.4219 0.776427 19.7024 0.659088C19.9828 0.54175 20.2836 0.480795 20.5876 0.479703L20.5959 2.7945L20.6042 5.1093Z" fill="white" fill-opacity="0.4"/>
                    </svg>
                  </div>
                  <div className="bg-[#00EFFF] blur-lg w-7 h-7 relative top-3"></div>
                  <div className="font-poppins-regular font-semibold self-start z-10">
                    Better Answer
                  </div>
                </div>
                <div id="complete-thought-tick" className="bg-[#FF9800] rounded-full h-[2vw] w-[2vw] absolute right-[-1vw] translate-y-[-150%] flex justify-center items-center animated-element text-left overflow-hidden">
                  <i className="fa-sharp fa-solid fa-plus text-white"></i>
                </div>
              </div>
            </div>
            <div className="animation-end w-full absolute bottom-0 h-16 text-left p-3 flex flex-row items-center">
              <i className="fa-solid fa-file-pdf text-white"></i>
              <div id="report-ready-bar" className="h-[50%] w-1 bg-white absolute animated-element"></div>
              <div id="report-ready-text" className="text-white font-poppins-regular pl-3 animated-element max-h-6 w-full text-[0.75vw]">
                Postcall Automation
              </div>
              <div className="rounded-full h-[6vw] w-[6vw] tick-circle border-white flex justify-center items-center ml-2 absolute left-[15vw]">
                <i id="report-ready-check" className="fa-sharp fa-solid fa-check text-white animated-element text-[1vw]"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="ml-5">
          <div className="flex flex-col text-left">
            <div className="font-poppins-extraBold text-[2.5vw] free-trial-heading mb-5">
              Engage customers with deep personalized understanding
            </div>
            <div className="font-poppins-regular text-[1vw] text-[#C2E3FF] my-5">
              NeoGPT automatically detects and enriches real-time context from customer conversations along with insights from customer data.
            </div>
            <a href="mailto:anuj@onfinance.in?subject=NeoGPT%20Free%20Trial&body=Please%20activate%20a%20NeoGPT%20free%20trial%20version%20for%20my%20organization.%20Regards">
              <button className="bg-white text-center rounded-2xl font-poppins-regular font-semibold text-[1.25vw] px-9 py-5 mt-5 w-fit">
                <h3 className="rm-get-trial-btn">Get Free Trial</h3>
              </button>
            </a>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="my-10 bg-[#01101D] py-16 relative">
        <div className="rm-animation-bg absolute top-[50%] left-[10%] translate-y-[-50%] h-[50%] w-[40%]"></div>
        <div className="absolute right-0">
          <img src={IMAGES.grid} alt="" />
        </div>
        <div className="mx-5">
          <div className="flex flex-col text-center">
            <div className="font-poppins-extraBold text-[8vw] free-trial-heading mb-5">
              Engage customers with deep personalized understanding
            </div>
            <div className="font-poppins-regular text-[6vw] text-[#C2E3FF] my-5">
              NeoGPT automatically detects and enriches real-time context from customer conversations along with insights from customer data.
            </div>
            <a href="mailto:anuj@onfinance.in?subject=NeoGPT%20Free%20Trial&body=Please%20activate%20a%20NeoGPT%20free%20trial%20version%20for%20my%20organization.%20Regards">
              <button className="bg-white text-center rounded-2xl font-poppins-regular font-semibold text-[5vw] px-9 py-5 mt-5">
                <h3 className="rm-get-trial-btn">Get Free Trial</h3>
              </button>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default RelationshipManagersAnimation