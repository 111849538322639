function FeatureCard(
  {
    heading,
    description,
    imgPath,
    imgAlt
  }
) {
  return (
    <div className="rounded-xl py-10 px-16 max-[650px]:px-10 flex flex-col w-fit text-left max-[850px]:my-16 rm-feature-card-bg">
      <div className="font-poppins-bold text-[2vw] max-[850px]:text-[5vw] pb-3 rm-feature-card-heading">
        {heading}
      </div>
      <div className="font-poppins-regular font-medium text-[1.25vw] max-[850px]:text-[3.5vw] py-6 rm-feature-card-description">
        {description}
      </div>
      <img className="pb-3 mt-auto w-auto max-w-[100%]" src={imgPath} alt={imgAlt} />
    </div>
  )
}

export default FeatureCard