import React, { useState, useEffect } from 'react';
import onfinanceLogo from '../static/images/onfinance-logo.png'
import DropdownMenu from './DropdownMenu';

function handleButtonClick(event) {
  const buttons = document.querySelectorAll('.navbar-btn');
  buttons.forEach((button) => {
    button.classList.remove("rm-navbar-btn-selected");
  });
  event.target.classList.add("rm-navbar-btn-selected");

  if (event.target.innerText === "Features") {
    window.open("https://www.onfinance.ai/#feature", "_blank")
  } else if (event.target.innerText === "NeoGPT") {
    window.open("https://www.onfinance.ai/", "_blank")
  } else if (event.target.innerText === "Pricing") {
    window.open("https://www.onfinance.ai/#pricing", "_blank")
  } else if (event.target.innerText === "Research") {
    window.open("https://research.onfinance.ai", "_blank")
  } else if (event.target.innerText === "Sales") {
    window.open("https://sales.onfinance.ai", "_blank")
  } else if (event.target.innerText === "Support") {
    window.open("https://cs.onfinance.ai", "_blank")
  } else if (event.target.innerText === "Contact Us") {
    window.open("https://www.onfinance.ai/#contact", "_blank")
  }
}

function Navbar() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 960);

  const handleResize = () => {
    setIsDesktop(window.innerWidth > 960);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <nav className="text-xl font-medium px-[8%] sticky top-0 z-50 rm-navbar">
      <div className="py-8">
        <div className="grid grid-flow-col">
          <div className="text-black">
            <div className="flex justify-start">
              <img src={onfinanceLogo} alt="Onfinance Logo" />
              <div className="pt-1 pl-2">OnFinance</div>
            </div>
          </div>
          {
            isDesktop ?
            <div className="flex justify-end text-[1.2vw]">
              <button className="py-2 mx-4 navbar-btn text-center" onClick={handleButtonClick}>Features</button>
              <button className="py-2 mx-4 navbar-btn text-center" onClick={handleButtonClick}>NeoGPT</button>
              <button className="py-2 mx-4 navbar-btn text-center" onClick={handleButtonClick}>Pricing</button>
              <button className="py-2 mx-4 navbar-btn text-center" onClick={handleButtonClick}>Research</button>
              <button className="py-2 mx-4 navbar-btn text-center rm-navbar-btn-selected" onClick={handleButtonClick}>Sales</button>
              <button className="py-2 mx-4 navbar-btn text-center" onClick={handleButtonClick}>Support</button>
              <button className="py-2 mx-4 navbar-btn text-center" onClick={handleButtonClick}>Contact Us</button>
            </div> : <DropdownMenu/>
          }
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
